
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SidenavContent extends Component {

    componentDidMount() {

        const menuLi = document.getElementsByClassName('sub-menu-title');

        for (let i = 0; i < menuLi.length; i++) {

            menuLi[i].onclick = function (event) {

                this.parentElement.classList.toggle('open');
            }
        }
    }

    
    render() {
        return (
            <div>
                <ul className="nav-menu">
                    <li className="menu no-arrow">
                        <NavLink to="/counties">
                            <FontAwesomeIcon icon="server" className="mr-3"/>
                            <span className="nav-text">Counties</span>
                        </NavLink>
                    </li>
                    {/* <li className="ui_tooltip menu">
                        <a role="button" href='#' onClick={e => e.preventDefault()} className="sub-menu-title">
                            <FontAwesomeIcon icon="clock" className="mr-3"/>
                            <span className="nav-text">Club Events</span>
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="list" to="/events/calendar">
                                    <FontAwesomeIcon icon="calendar" className="mr-3"/>
                                    <span className="nav-text">Calendar</span>
                                </NavLink>
                            </li>
                        </ul>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="list" to="/events/list">
                                    <FontAwesomeIcon icon="list" className="mr-3"/>
                                    <span className="nav-text">List</span>
                                </NavLink>
                            </li>
                        </ul>
                    </li> */}
                </ul>
            </div>
        );
    }
}

export default withRouter(connect(null, {})(SidenavContent));
