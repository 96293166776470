import React from 'react';
import './index.scss'


class PageLayout extends React.Component {

    render() {
        return (
            <div className="page-layout p-md-5 p-2">
                <div className="d-flex justify-content-between align-items-center mb-md-4 mb-3">
                    <h3 className="text-gray-800 mb-0">{this.props.title}</h3>
                    {this.props.controls}
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default PageLayout