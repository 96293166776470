import React from 'react';
import { connect } from 'react-redux';
import store from 'store'
import {
    showMessage,
    updateUser,
    handleHTTPError
} from 'store/actions';
import { login } from 'apis';
import { validateEmail} from 'utils';
import TextBox from 'components/form/textbox'
import LoadingButton from 'components/form/loaddingbutton'
import './index.scss'


class Login extends React.Component {

    constructor () {

        super ();

        this.state = {

            showMsg: false,

            email: '',

            password: '',

            validate: false,

            loading: false,
        }
    }


    componentDidMount = () => {}


    login = () => {

        if (this.state.loading) return;

        this.setState({validate: true});

        if (!this.state.email|| !validateEmail(this.state.email) || !this.state.password) return;
        
        const params = {

            email: this.state.email,

            password: this.state.password,
        }

        this.setState({loading: true})

        login(params).then(data => {

            this.setState({ loading: false})
            
            this.props.updateUser(data.data);

            store.set('token', data.token)

            this.props.history.push('/')
                
        }).catch(error => {

            console.log(error)

            this.setState({ loading: false})

            this.props.handleHTTPError(error)
        })
        
    }

    render () {
        
        return (

            <div  className="signin">
                <div className="content">
                    <div className="signin-form shadow-md">
                        <img src="/logo.png" alt="logo"/>
                        <h3 className="text-primary text-center">Vaccination</h3>
                        <h6 className="text-primary text-center">(Super Admin)</h6>
                        <div className="form-group mb-4">
                            <label htmlFor="email">Email</label>
                            <TextBox tabIndex="0" id="email" name="email" type='text' 
                                validate = {this.state.validate} value={this.state.email} placeholder="Email"
                                onBlur={(e)=>{}}
                                onChange={(email, e) => {
                                    this.setState({
                                        email: email
                                    })
                                }}
                                validationOption={{
                                    showMsg: false,
                                    name: 'Email',
                                    reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    regMsg: 'Invalid Email',
                                    check: true,
                                    required: true, 
                                }}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="email">Password</label>
                            <TextBox tabIndex="0" id="password" name="password" type='password' 
                                validate = {this.state.validate} value={this.state.password} placeholder="Password"
                                onBlur={(e)=>{}}
                                onChange={(password, e) => {
                                    this.setState({
                                        password: password
                                    })
                                }}
                                validationOption={{
                                    showMsg: false,
                                    name: 'Password',
                                    check: true,
                                    required: true, 
                                }}
                            />
                        </div>
                        <LoadingButton loading={this.state.loading} onClick={this.login} title="LOGIN" className="w-100 mb-4 primary"/>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (reducer) => {

    const { user, isLoader, showMsg, message } = reducer;

    return { isLoader, showMsg, message, user }
};


export default connect (mapStateToProps, {

    showMessage,

    updateUser,

    handleHTTPError

}) (Login);
