import {
    HTTP_401_ERROR,
	HTTP_OTHER_ERROR,
    SHOW_MESSAGE,
    TOGGLE_SIDEBAR,
    WINDOW_WIDTH,
    UPDATE_USER,
    LOGOUT,
} from '../actionTypes';



export const excute401ErrorHandler = (error) => {

	return {
		type: HTTP_401_ERROR,
		payload: error
	};
}


export const excuteOtherErrorHandler = (error) => {

	return {
		type: HTTP_OTHER_ERROR,
		payload: error
	};
}



export const handleHTTPError = (error) => {

	if (error.status === 401)
    
		return excute401ErrorHandler(error)

	else
		return excuteOtherErrorHandler(error)
}


export const toggleSidebar = (data) => {

    return {
        type: TOGGLE_SIDEBAR, 
        payload: data
    };
};

export const updateWindowWidth = (width) => {
	
    return {
        type: WINDOW_WIDTH,
        payload: width
    };
}


export const showMessage = (data) => {

    return {
        type: SHOW_MESSAGE,
        payload: data
    };
};


export const updateUser = (data) => {
    return {
        type: UPDATE_USER,
        payload: data,
    };
};

export const logout = () => {
    return {
        type: LOGOUT,
    };
};
