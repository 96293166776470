import React from 'react';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {toggleSidebar} from 'store/actions';
import UserInfo from './userInfo'
import './index.scss'

class Header extends React.Component {

    constructor() {

        super();

        this.state = {

            openDropDown: false
        }
    }


    toggleDropDown = () => {

        this.setState({ openDropDown: !this.state.openDropDown })
    };

    
    render() {
        
        return (
            <div className="app-header">
                <div className="app-toolbar">
                    <div className="toggle-btn align-items-center d-flex justify-content-center">
                        <span className={`jr-menu-icon pointer d-flex d-xl-none`}
                            onClick={()=>this.props.toggleSidebar(true)}>
                        <span className="menu-icon bg-white"/></span>
                    </div>
                    <ul className="list-inline ml-auto">
                        <li className="list-inline-item user-info pointer">
                            <Dropdown
                                className="quick-menu"
                                isOpen={this.state.openDropDown}
                                toggle={this.toggleDropDown}>

                                <DropdownToggle
                                    className="d-inline-block"
                                    tag="span"
                                    data-toggle="dropdown">
                                    <img alt='...' src="https://via.placeholder.com/30" className="user-avatar mr-2"/>
                                    <FontAwesomeIcon icon="caret-down"/>
                                </DropdownToggle>

                                <DropdownMenu right className="shadow">
                                    <UserInfo/>
                                </DropdownMenu>

                            </Dropdown>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (reducer) => {

    const {user} = reducer;

    return {user};
}


export default withRouter(connect(mapStateToProps, {

    toggleSidebar

})(Header));