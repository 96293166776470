import React from 'react';
import { Route, Redirect, Switch} from 'react-router-dom';
import loadable from '@loadable/component'
import Layout from 'components/layout';


const Counties = loadable(() => import('./counties'))


class PrivateRoutes extends React.Component {

    constructor(props){

        super(props);

        this.state = { }
    }

    
    render() {

        return (
            <Layout>
                <Switch>
                    <Route path='/counties' component={Counties}/> 
                    <Redirect path='/' to={'/counties'}/> 
                </Switch>
            </Layout>
        );
    }
}


export default PrivateRoutes;