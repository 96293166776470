import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import './index.scss'

const CustomScrollbars = (props) => <Scrollbars  
    {...props} 
    autoHide
    renderTrackHorizontal={props => 
        <div {...props}
            className="scrollbar-horizontal" 
        />}
    renderThumbVertical={props => 
        <div {...props}
            className='bg-white scrollbar-vertical'
        />}
/>;

export default CustomScrollbars;