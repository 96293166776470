exports.validateEmail = (value) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
}

exports.validatePhone = (value) => {
    var re = /^[0-9]{8,10}$/
    return re.test(value);
}

exports.validateId = (value) => {
    var re = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
    return re.test(value);
}

const convertArrayOfObjectsToCSV = (args) => {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    
    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
        ctr = 0;
        keys.forEach(function (key) {
            if (ctr > 0) {
                result += columnDelimiter;
            }
            result += typeof item[key] === "string" ? `"${item[key]}"` : item[key];
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

exports.downloadCSV = (datas, file_name) => {
    const csv = convertArrayOfObjectsToCSV({
        data: datas
    });
    if (csv == null) {
        return;
    }
    const FileSaver = require('file-saver');
    const blob = new Blob([csv], {type: "data:text/csv;charset=utf-8"});
    FileSaver.saveAs(blob, `${file_name}.csv`);
}

exports.randomEventColor = () => {
    const decColorNum = Math.floor(Math.random() * (8355711 - 2000000 + 1) + 2000000);
    return decColorNum.toString(16)
}