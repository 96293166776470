import axios from 'axios';
import API_URL from './ApiUrl'
import store from 'store';


axios.interceptors.request.use(function (config) {

	config.baseURL = API_URL.BASE_URL;
	config.timeout = 15000;
	
	const token = store.get('token');
	config.headers.Authorization =  token ? `Bearer ${token}` : '';

	return config;
});


axios.interceptors.response.use(function (response) {

    return response;

}, function (error) {

    if (!error.response) return Promise.reject({
		response: {
			status: 408,
			message: 'Request Timeout'
		}
	})

	return Promise.reject(error);
});


export const register = (params) => {

    return axios.post(`${API_URL.REGISTER_URL}`, params)
    
    .then(data => data.data)
    
    .catch(error => {throw error.response});
};


export const login = (params) => {

    return axios.post(`${API_URL.LOGIN_URL}`, params)
    
    .then(data => data.data)
    
    .catch(error => {throw error.response});
};


export const createCounty = (params) => {

    return axios.post(`${API_URL.COUNTYSYSTEM_URL}`, params)
    
    .then(data => data.data)
    
    .catch(error => {throw error.response});
};


export const updateCounty = (params) => {

    return axios.patch(`${API_URL.COUNTYSYSTEM_URL}`, params)
    
    .then(data => data.data)
    
    .catch(error => {throw error.response});
};


export const readCounty = (params) => {

    return axios.get(`${API_URL.COUNTYSYSTEM_URL}/${params._id}`)
    
    .then(data => data.data)
    
    .catch(error => {throw error.response});
};


export const retrieveCounties = (params) => {

    return axios.get(`${API_URL.COUNTYSYSTEM_URL}`)
    
    .then(data => data.data)
    
    .catch(error => {throw error.response});
};


export const backupCountyDB = (params) => {

    const { countyID, ...requestData} = params

    return axios.post(`${API_URL.DBMANAGE_URL}/${countyID}/backup`, requestData)
    
    .then(data => data.data)
    
    .catch(error => {throw error.response});
};


export const downloadCountyDB = (params) => {

    const { countyID, fileName} = params

    return axios.get(`${API_URL.DBMANAGE_URL}/${countyID}/files/${fileName}`, {responseType: 'blob'})
    
    .then(data => data.data)
    
    .catch(error => {throw error.response});
};


export const restoreCountyDB = (params) => {

    const { countyID, ...requestData} = params

    return axios.post(`${API_URL.DBMANAGE_URL}/${countyID}/restore`, requestData)
    
    .then(data => data.data)
    
    .catch(error => {throw error.response});
};


export const uploadCountyDBBackupFile = (params) => {

    const formData = new FormData();

    formData.set('file', params.file)

    return axios.post(`${API_URL.DBMANAGE_URL}/${params.countyID}/upload`, formData, { headers: {'content-type': 'multipart/form-data'}})

    .then(data => data.data)
    
    .catch(error => {throw error.response});
};


export const retrieveCountyDBFiles = (params) => {

    const { countyID} = params

    return axios.get(`${API_URL.DBMANAGE_URL}/${countyID}/files`)
    
    .then(data => data.data)
    
    .catch(error => {throw error.response});
};

