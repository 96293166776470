export default {

    BASE_URL: `${process.env.REACT_APP_SERVER_URL}/api/`,

    // auth
    REGISTER_URL: 'register',
    LOGIN_URL: 'login',
    COUNTYSYSTEM_URL: 'counties',
    DBMANAGE_URL: 'databases'
}

