import React, { Component } from 'react'
import { Textbox } from 'react-inputs-validation';
import './index.scss'

export default class TextBox extends Component {

    render() {
        return (
            <div className={this.props.className} style={this.props.style}>
                {
                    this.props.labelHTML &&
                    <label htmlFor={this.props.id}>{this.props.labelHTML}</label>
                }
                {
                   this.props.readOnly ?
                   <input id={this.props.id} name={this.props.name} className="readOnly" readOnly value={this.props.value} style={{height: `${this.props.size || '45'}px`}}/>:
                    <Textbox 
                        attributesWrapper={{}} // Optional.
                        attributesInput={{ // Optional.
                            id: this.props.id,
                            name: this.props.name,
                            type: this.props.type,
                            placeholder: this.props.placeholder,
                            tabIndex:this.props.tabIndex
                        }}
                        disabled={this.props.disabled}
                        validate = {this.props.validate} 
                        validationCallback={this.props.validationCallback}
                        value={this.props.value} 
                        onBlur={this.props.onBlur}
                        classNameContainer={this.props.classNameContainer}
                        classNameInput={this.props.classNameInput}
                        onChange={(value, e) => {
                            if (this.props.onChange) this.props.onChange(value)
                        }}
                        customStyleContainer={this.props.customStyleContainer}
                        customStyleInput={{
                            backgroundColor: 'white',
                            height: `${this.props.size || '45'}px`,
                            maxHeight: '45px',
                            width: '100%',
                            padding: '0 2%',
                            fontSize: `${this.props.fontSize || '13'}px`,
                        }}
                        customStyleWrapper={{
                            height: `${this.props.size || '45'}px`,
                            width: '100%',
                            maxHeight: '45px',
                        }}
                        validationOption={this.props.validationOption}
                    />
                }
            </div>
        )
    }
}
