import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Sidebar from "react-sidebar";
import SidenavContent from './SidenavContent';
import CustomScrollbars from 'components/custom_scrollbar';
import SidebarLogo from './SidebarLogo';
import {
    toggleSidebar, 
    updateWindowWidth
} from 'store/actions';
import './index.scss'

class SideBar extends React.PureComponent {

    constructor(props) {

        super(props);
        
        this.state = {}
    }

    componentDidMount() {

        window.addEventListener('resize', () => {

            this.props.updateWindowWidth(window.innerWidth)
        });
    }

    render() {
        
        return (
            <Sidebar
                sidebarClassName="app-sidebar"
                sidebar={<div className="side-nav">
                    <SidebarLogo/>
                    <CustomScrollbars className="scrollbar">
                        <SidenavContent/>
                    </CustomScrollbars>
                </div>}
                open={this.props.openSidebar}
                onSetOpen={()=>this.props.toggleSidebar(!this.props.openSidebar)}
                styles={{ sidebar: { background: "white" } }}
                docked={this.props.width > 1200}
                transitions={true}
            ><div></div></Sidebar>
        );
    }
}


const mapStateToProps = (reducer) => {

    const {openSidebar, width, user} = reducer;

    return {openSidebar, width, user}
};


export default withRouter(connect(mapStateToProps, {

    toggleSidebar, 

    updateWindowWidth

})(SideBar));
