import React from 'react';
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    logout
} from 'store/actions'


class UserInfo extends React.Component {


    render() {
        
        return (
            <>
                <div className="user-profile">
                    <h6 className="user-name mb-0">{this.props.user.name}</h6>
                </div>
                <div className="dropdown-item user-select-none pointer" onClick={(e) => {
                }}> <FontAwesomeIcon icon="user"/> &nbsp;&nbsp;&nbsp;Profile
                </div>
                <div className="dropdown-item user-select-none pointer" onClick={this.props.logout}>
                    <FontAwesomeIcon icon="sign-out-alt"/> &nbsp;&nbsp;&nbsp;Logout
                </div>
            </>
        );
    }
}

const mapStateToProps = (reducer) => {

    const { user } = reducer;

    return { user };
}


export default connect(mapStateToProps, {

    logout

})(UserInfo);


