
// http error

export const HTTP_401_ERROR = 'http_unauthorized_error'

export const HTTP_OTHER_ERROR = 'http_other_error'


// Ui 
export const SHOW_MESSAGE = 'show_message';

export const TOGGLE_SIDEBAR = 'toggle_sidebar'

export const WINDOW_WIDTH = 'window_width';


//user
export const UPDATE_USER = 'update_user'

export const LOGOUT = 'logout'