import {
    HTTP_401_ERROR,
	HTTP_OTHER_ERROR,
    SHOW_MESSAGE,
    TOGGLE_SIDEBAR,
    WINDOW_WIDTH,
    UPDATE_USER,
    LOGOUT
} from '../actionTypes';

import store from 'store'

const INIT_STATE = {
    user: store.get('user') || null,
    openSidebar: false,
    width: window.innerWidth,
    message: null,
    showMsg: false,
};



export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case HTTP_401_ERROR:

			store.clearAll();
            window.location.href = "/signin"
            return { ...state };

        case HTTP_OTHER_ERROR:

            return { ...state, showMsg: true, message: { type: 'error', text: action.payload.data ? action.payload.data.message || 'Unknow Error' : String(action.payload)}}

		case SHOW_MESSAGE:

            return { ...state, showMsg: action.payload.showMsg, message: action.payload.message }

        case TOGGLE_SIDEBAR:

            return { ...state, openSidebar: action.payload };

        case WINDOW_WIDTH: 

            return { ...state, width: action.payload };

        case LOGOUT: 

            store.clearAll()
            return { ...state, user: null }

        case UPDATE_USER: 

            store.set('user', action.payload)
            return { ...state, user: action.payload }

        default:

            return state;
    }
}
