import React, { Component } from 'react'
import Loader from 'components/loader'
import './index.scss'

class LoadingButton extends Component {

    render() {

        return (
            <button className={`${this.props.className || ''} loading-btn`} onClick={this.props.onClick}>
                {
                    this.props.loading &&
                    <div className="loading"><Loader size={25} color="white"/></div>
                }
                <div>{this.props.title}</div>
            </button>
        )
    }
}

export default LoadingButton
