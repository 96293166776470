import React, { Component } from 'react'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

class SidebarLogo extends Component {

    constructor(props) {

        super(props)
        
        this.state = {}
    }
    

    render() {
        return (
            <div className="sidebar-logo pointer py-4">
                <img src="/logo.png"  alt="" onClick={() => {this.props.history.push('/')}}/>
                <p className="p-0 m-0 mt-2">(System Admin)</p>
            </div>
        )
    }
}

const mapStateToProps = (reducer) => {

    const { user } = reducer;

    return { user }
};


export default withRouter(connect(mapStateToProps, {})(SidebarLogo));
